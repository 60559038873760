import React from "react";

import Helmet from "react-helmet";

import { css } from "@emotion/core";
import styled from "@emotion/styled";

import Page from "../../../shared/Page";
import ShippingPriceListModal from "../components/ShippingPriceListModal";

export const Container = styled.div`
    ${({ theme }) => css`
        ${theme.paddings.containerOuter.left}
        ${theme.paddings.containerOuter.right}

        margin-top: 6em;
        color: ${theme.colors.blue.toString()};
    `}
`;

/**
 * Base Template for the Shop Routes to ensure paddings meta etc. Snackbar is set for all Shop Routes
 * @param title
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
const ShopBaseTemplate = ({ title = "Hoyer Shop", children }) => {
    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <ShippingPriceListModal />
            <Page>
                <Container>{children}</Container>
            </Page>
        </>
    );
};

export default ShopBaseTemplate;
