import React, { useState } from "react";

import styled from "@emotion/styled";

import ShopBaseTemplate from "../../../../components/pages/Shop/ShopBaseTemplate";
import { lastVisitedStorePathKey } from "../../../../components/pages/Shop/ShoppingCart/store";
import { Link } from "../../../../components/shared/LinkComponent";
import NewHeadline from "../../../../components/shared/NewHeadline";
import { storageSession } from "../../../../utils/StorageHandler";

const Centered = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Content = styled.section`
    max-width: 400px;
    padding-bottom: 5em;
`;

const StyledLink = styled(Link)`
    text-decoration: underline;
`;

const ShopBestellungFehlerBezahlungPage = () => {
    // Redirect the user back to the shop storefront.
    const [storefrontUrl] = useState(
        storageSession.get(lastVisitedStorePathKey, "/")
    );

    return (
        <ShopBaseTemplate title="Hoyer Shop - Zahlung fehlgeschlagen">
            <Centered>
                <Content>
                    <NewHeadline lookLike="2">
                        Bestellung fehlgeschlagen
                    </NewHeadline>
                    <p>
                        Deine Bestellung konnte leider nicht übermittelt werden,
                        da der Zahlungsvorgang nicht abgeschlossen wurde.
                    </p>
                    <p>
                        Klicke <StyledLink to={storefrontUrl}>hier</StyledLink>{" "}
                        um zum Shop zu gelangen.
                    </p>
                </Content>
            </Centered>
        </ShopBaseTemplate>
    );
};

export default ShopBestellungFehlerBezahlungPage;
